.about {
  margin: 2rem 0;
  &__management {
    h2 {
      padding: 2rem 0;
    }
    &--item {
      margin-bottom: 2rem;
      font-size: 1.5rem !important;
      //   padding-right: 1rem;

      figure {
        img {
          width: 80%;
          height: auto;
          border-radius: 8px;
        }
      }
      &_name {
        font-size: 1.1rem;
        // font-weight: 600;
        // margin-bottom: 1rem;
      }
      &_post {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
}
