.bton {
  font-weight: $font-bold;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  margin: 0.5rem;

  width: fit-content;
  transition: all 0.2s ease-in-out;

  &--sm {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 34px;
  }
  &--md {
    padding-left: 28px;
    padding-right: 28px;
    line-height: 39px;
  }
  &--lg {
    padding-left: 36px;
    padding-right: 36px;
    line-height: 39px;
  }
  &--full {
    line-height: 46px;
    margin: 0;
    width: 100%;
    font-weight: 100;
  }
  &--half {
    line-height: 48px;
    width: 50%;
  }

  &--primary {
    border: 0px;
    padding: 10px 30px;
    border-color: color(primary, base);
    color: white;
    border-radius: 31px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    width: fit-content;
    background-color: color(primary, base);
    &:hover {
      background-color: color(primary, hover);
      border-color: color(primary, hover);
      color: white;
    }
    &:active {
      border-color: color(primary, clicked);
    }
  }
  &--secondary {
    border: 3px solid;
    padding: 10px 30px;

    border-color: color(primary, base);
    color: color(primary, base);
    border-radius: 31px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    width: fit-content;
    background-color: transparent;
    &:hover {
      background-color: color(primary, base);
      color: white;
    }
  }
  &--ghost {
    border-radius: 4px;
    border-color: color(primary, base);
    color: color(primary, base);
    background-color: color(white);
    &:hover {
      background-color: color(primary, hover);
      border-color: color(primary, hover);
      color: white;
    }
    &:active {
      border-color: color(primary, clicked);
    }

    &--danger {
      border-color: color(danger, base);
      background: color(danger, tint);
      color: color(danger, base);

      &:hover {
        background-color: color(danger, base);
        border-color: color(danger, base);
        color: white;
      }
    }
  }

  &--danger {
    background: color(danger, base);
    color: white;
    border: color(danger, base);
  }

  &--nacked {
    border: none;
    color: rgb(20, 20, 185);
    background: none;
    padding: 0;
  }
}
