.about {
  &__message {
    padding: 2rem 0;

    .row {
      flex-wrap: wrap-reverse;
    }
    &--content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-overflow: ellipsis;
      height: 100%;
      font-size: 1.1rem;
      h2 {
        margin-bottom: 1rem;
        text-transform: capitalize;
        font-size: 2rem !important;
      }
      &-overflow {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 12;
        overflow: hidden;
        margin: 0;
      }
      &-button {
        border: none;
        background-color: transparent;
        color: blue;
        padding: 0;
      }
      &__para {
        text-align: justify;
        margin: 0;
        line-height: 180%;
      }
    }
    &--img {
      width: 100%;
      height: 100%;
      display: flex;
      // flex-wrap: wrap-reverse;
      // justify-content: end;
      @include responsive-for(lg) {
        justify-content: start;
      }
      figure {
        width: 100%;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }
}
