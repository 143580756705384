.footer {
  background-color: #191a1a;
  margin-top: 2rem;
  border-radius: 16px 16px 0 0;
  padding: 4rem;

  &__item {
    color: #fff;

    line-height: 2.5rem;
    h3 {
      margin-bottom: 1.2rem;
    }
    span {
      margin-left: 1rem;
    }
    li {
      font-size: 1.2rem;
    }
    &--contact {
      display: flex;
      align-items: center;
      color: #fff;
      span {
        word-break: break-word;
      }

      &:hover {
        color: #fff;
      }
    }
    &--content {
      &-social {
        display: flex;
        align-items: center;
        width: 12rem;
        justify-content: space-between;
        div {
          display: flex;
          align-items: center;

          span {
            color: #fff;

            font-size: 1.2rem;
          }
        }
      }
    }
    @include responsive-for(lg) {
      margin-bottom: 2rem;
    }
  }
  @include responsive-for(xs) {
    padding: 2rem;
  }
  &__section {
    margin: 0 4rem;
    @media (max-width: 425px) {
      margin: 0;
    }
  }
}
