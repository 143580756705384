.popup {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 100vw;

    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem;

        &-item {
            max-width: 800px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1.5rem 1rem;

            @include responsive-for(sm) {
                gap: 2rem;
                padding: 0.5rem;
            }
            background-color: white;
            border: 1px solid rgba(57, 57, 57, 0.3);

            border-radius: 10px;
            &-header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                h3 {
                    margin: 0;
                    padding: 0;
                }

                button {
                    background: transparent;
                    outline: none;
                    border: none;
                    font-size: 20px;
                }
            }
            &-image {
                text-align: center;
                min-height: 70vh;

                .carousel-control-next,
                .carousel-control-prev {
                    display: block;
                    opacity: 1;
                    padding-top: 20px;

                    @include responsive-for(sm) {
                        padding-top: 0;
                        opacity: 0.4;
                    }
                }

                @include responsive-for(sm) {
                    min-height: 40vh;
                }

                span {
                    font-size: 20px;
                }
                img {
                    width: 768px;

                    @include responsive-for(sm) {
                        width: 80vw;
                    }
                }
            }
        }
    }
}
