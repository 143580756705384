.my-navbar {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;
  display: block;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
  &__main {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include responsive-for(sm) {
      padding: 1rem 0;
    }
  }

  .nav {
    @include responsive-for(lg) {
      display: none;
    }
    &-links {
      color: black;
      position: relative;
      margin-right: 3rem;

      &::after {
        content: "";
        position: absolute;
        background-color: black;
        width: 0%;
        left: 0%;
        top: 100%;
        height: 2px;
      }

      &:hover {
        &::after {
          position: absolute;
          background-color: black;
          width: 100%;
          left: 0%;
          top: 100%;
          height: 2px;
          transition: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-duration: 300ms;
        }
      }
    }

    .active {
      position: relative;
      &::after {
        content: "";

        position: absolute;
        background-color: black;
        width: 100%;
        left: 0%;
        top: 100%;
        height: 2px;
      }
    }

    &-mobile {
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 20rem;
      background-color: #fff;
      padding: 1rem;
      // padding-right: 2rem;
      border-left: 1px solid #ccc;
      transition: 0.3s;
      display: flex;
      flex-direction: column;
      &__list {
        &--item {
          font-size: 1.2rem;
          margin: 1.5rem 0;
          border-bottom: 1px solid #ccc;

          color: black;

          &:hover,
          &:active {
            background-color: lighten($color: $primary, $amount: 40%);
          }
        }
      }
    }
  }
  .contact-bton {
    padding: 0.35rem 1rem;
    @include responsive-for(lg) {
      display: none;
    }
  }
  .list {
    margin-left: 1rem;
    height: 20px;
    width: 20px;
    @media (min-width: 993px) {
      display: none;
    }
  }
  .close {
    height: 30px;
    width: 30px;
    align-self: flex-end;
    &:hover,
    &:active {
      color: $primary;
    }
  }
  .logo {
    @media (max-width: 376px) {
      width: 250px;
    }
  }
}
.nav-mobile__section {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
}
