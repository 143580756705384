svg:not(:root) {
  overflow: unset;
}
html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  font-family: "DM Sans", sans-serif;
  color: color(text-dark);
}
body {
  margin: 0;
  font-weight: $font-regular;
  background: #fff;
  font-family: "DM Sans", sans-serif;
  font-family: "DM Sans", sans-serif;
  color: color(text-dark);
}
h1,
.h1,
h2,
.h2
// h3,
// .h3,
// h4,
// .h4,
// h5,
// .h5,
// h6,
// .h6
{
  margin-top: 0;
  // margin-bottom: 0.1rem;
  color: color(text-dark);
  // line-height: 150%;
  font-family: "DM Sans", sans-serif;
  font-family: "DM Sans", sans-serif;
}
h1 {
  font-size: $h1;
  line-height: 68px;
  font-weight: $font-bold !important;
  @include responsive-for(md) {
    font-size: 2.75rem;
    line-height: 3rem;
  }
}
h2 {
  font-size: $h2;

  font-weight: $font-semi-bold !important;
  color: color(text-dark);
  @include responsive-for(md) {
    font-size: 2rem;
  }
}
h3 {
  font-size: $h3;
  // font-weight: $font-bold;
}
h4 {
  font-size: $h4;
}

p {
  margin-top: 0;
  line-height: 150%;
  font-size: $font-regular;
  color: color(text-light);
}
button,
textarea,
input {
  &:focus {
    font-family: "DM Sans", sans-serif;

    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

a {
  text-decoration: none !important;
  // color: unset;
  cursor: pointer;
  &:hover {
    color: black;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix {
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

// cursor pointer

.form-check-input {
  cursor: pointer;
}

//table customization
thead {
  background: color(success, base);
  color: white;
}
th {
  font-weight: $font-medium;
  font-size: $text-lg;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}
td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: $text-sm;
  color: color(text-dark);
}

thead {
  border-style: hidden !important;
}
