.contact {
  margin: 2rem 0;
  &-form {
    margin-right: 2rem;
    margin-bottom: 3rem;
    @include responsive-for(lg) {
      margin-right: 0;
    }
    h2 {
      font-size: 2rem;
      line-height: 2rem;
      margin: 0;
    }
    small {
      color: $secondary-text;
      font-size: 0.8rem;
    }
    &__main {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;

      label {
        font-size: 0.9rem;
        display: flex;
        margin-bottom: 0.3rem;
      }
      input {
        border-radius: 20px;
        border: none;
        background-color: #efefef;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
        font-size: 0.9rem;
        &::placeholder {
          font-size: 0.9rem;
        }
        @include responsive-for(lg) {
          width: 15rem;
        }
      }
      &--row {
        display: flex;
        justify-content: space-between;
        @include responsive-for(xs) {
          flex-direction: column;
        }
      }
    }
    &__item {
      margin-bottom: 1rem;
      textarea {
        border-radius: 20px;
        border: none;
        background-color: #efefef;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        &::placeholder {
          font-size: 0.9rem;
        }
      }
      &--message {
        width: 100%;
      }
      span {
        display: flex;
        font-size: 0.75rem;
        color: red;
      }
    }
  }
  &-map {
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 6px;
    }
    @include responsive-for(lg) {
      height: 25rem;
    }
  }
  .submit {
    width: 100%;
    padding: 0.5rem 0;
  }
}
