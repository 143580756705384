/* 2.3 Font weight*/
/* 2.1 Font Size*/
/* 3.0 Heading Size*/
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
* {
  font-family: "DM Sans", sans-serif;
}

.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: 3.75rem;
}

.h2 {
  font-size: 2.5rem;
}

.color-primary {
  color: #2956c9;
}
.color-gray1 {
  color: #b3b3b3;
}
.color-gray2 {
  color: #cccccc;
}
.color-gray3 {
  color: #e6e6e6;
}
.color-gray4 {
  color: #f4f4f4;
}

.pagination {
  justify-content: flex-end;
}

.page-link {
  color: #2956c9 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #2956c9 !important;
  border-color: #2956c9 !important;
}

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: 24px;
}
.modalBody-title {
  text-align: center;
  margin-bottom: 1rem;
}

.react-link {
  text-decoration: inherit;
  color: inherit;
}
.react-link:focus, .react-link:hover, .react-link:visited, .react-link:link, .react-link:active {
  text-decoration: inherit;
  color: inherit;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: #3c4043;
  width: 12rem !important;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #2956c9 !important;
  background: white !important;
  font-weight: 600;
  border-bottom: 2px solid;
  width: 9rem;
  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: #3c4043 !important;
  cursor: pointer;
}

.cpntr {
  cursor: pointer;
}

svg:not(:root) {
  overflow: unset;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  font-family: "DM Sans", sans-serif;
  color: #000000;
}

body {
  margin: 0;
  font-weight: 400;
  background: #fff;
  font-family: "DM Sans", sans-serif;
  font-family: "DM Sans", sans-serif;
  color: #000000;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  color: #000000;
  font-family: "DM Sans", sans-serif;
  font-family: "DM Sans", sans-serif;
}

h1 {
  font-size: 3.75rem;
  line-height: 68px;
  font-weight: 700 !important;
}
@media all and (max-width: 768px) {
  h1 {
    font-size: 2.75rem;
    line-height: 3rem;
  }
}

h2 {
  font-size: 2.5rem;
  font-weight: 600 !important;
  color: #000000;
}
@media all and (max-width: 768px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

p {
  margin-top: 0;
  line-height: 150%;
  font-size: 400;
  color: #3c4043;
}

button:focus,
textarea:focus,
input:focus {
  font-family: "DM Sans", sans-serif;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}
a:hover {
  color: black;
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.form-check-input {
  cursor: pointer;
}

thead {
  background: #3cc13b;
  color: white;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: 14px;
  color: #000000;
}

thead {
  border-style: hidden !important;
}

.bton {
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  margin: 0.5rem;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.2s ease-in-out;
}
.bton--sm {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 34px;
}
.bton--md {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 39px;
}
.bton--lg {
  padding-left: 36px;
  padding-right: 36px;
  line-height: 39px;
}
.bton--full {
  line-height: 46px;
  margin: 0;
  width: 100%;
  font-weight: 100;
}
.bton--half {
  line-height: 48px;
  width: 50%;
}
.bton--primary {
  border: 0px;
  padding: 10px 30px;
  border-color: #2956c9;
  color: white;
  border-radius: 31px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #2956c9;
}
.bton--primary:hover {
  background-color: #142c6a;
  border-color: #142c6a;
  color: white;
}
.bton--secondary {
  border: 3px solid;
  padding: 10px 30px;
  border-color: #2956c9;
  color: #2956c9;
  border-radius: 31px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
}
.bton--secondary:hover {
  background-color: #2956c9;
  color: white;
}
.bton--ghost {
  border-radius: 4px;
  border-color: #2956c9;
  color: #2956c9;
  background-color: #ffffff;
}
.bton--ghost:hover {
  background-color: #142c6a;
  border-color: #142c6a;
  color: white;
}
.bton--ghost--danger {
  border-color: #f03738;
  background: #fcd7d7;
  color: #f03738;
}
.bton--ghost--danger:hover {
  background-color: #f03738;
  border-color: #f03738;
  color: white;
}
.bton--danger {
  background: #f03738;
  color: white;
  border: #f03738;
}
.bton--nacked {
  border: none;
  color: rgb(20, 20, 185);
  background: none;
  padding: 0;
}

.error figure {
  display: flex;
  justify-content: center;
}
.error figure img {
  width: 50%;
}

.my-navbar {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;
  display: block;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
}
.my-navbar__main {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 576px) {
  .my-navbar__main {
    padding: 1rem 0;
  }
}
@media all and (max-width: 992px) {
  .my-navbar .nav {
    display: none;
  }
}
.my-navbar .nav-links {
  color: black;
  position: relative;
  margin-right: 3rem;
}
.my-navbar .nav-links::after {
  content: "";
  position: absolute;
  background-color: black;
  width: 0%;
  left: 0%;
  top: 100%;
  height: 2px;
}
.my-navbar .nav-links:hover::after {
  position: absolute;
  background-color: black;
  width: 100%;
  left: 0%;
  top: 100%;
  height: 2px;
  transition: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-duration: 300ms;
}
.my-navbar .nav .active {
  position: relative;
}
.my-navbar .nav .active::after {
  content: "";
  position: absolute;
  background-color: black;
  width: 100%;
  left: 0%;
  top: 100%;
  height: 2px;
}
.my-navbar .nav-mobile {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 20rem;
  background-color: #fff;
  padding: 1rem;
  border-left: 1px solid #ccc;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}
.my-navbar .nav-mobile__list--item {
  font-size: 1.2rem;
  margin: 1.5rem 0;
  border-bottom: 1px solid #ccc;
  color: black;
}
.my-navbar .nav-mobile__list--item:hover, .my-navbar .nav-mobile__list--item:active {
  background-color: #cad6f4;
}
.my-navbar .contact-bton {
  padding: 0.35rem 1rem;
}
@media all and (max-width: 992px) {
  .my-navbar .contact-bton {
    display: none;
  }
}
.my-navbar .list {
  margin-left: 1rem;
  height: 20px;
  width: 20px;
}
@media (min-width: 993px) {
  .my-navbar .list {
    display: none;
  }
}
.my-navbar .close {
  height: 30px;
  width: 30px;
  align-self: flex-end;
}
.my-navbar .close:hover, .my-navbar .close:active {
  color: #2956c9;
}
@media (max-width: 376px) {
  .my-navbar .logo {
    width: 250px;
  }
}

.nav-mobile__section {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
}

.footer {
  background-color: #191a1a;
  margin-top: 2rem;
  border-radius: 16px 16px 0 0;
  padding: 4rem;
}
.footer__item {
  color: #fff;
  line-height: 2.5rem;
}
.footer__item h3 {
  margin-bottom: 1.2rem;
}
.footer__item span {
  margin-left: 1rem;
}
.footer__item li {
  font-size: 1.2rem;
}
.footer__item--contact {
  display: flex;
  align-items: center;
  color: #fff;
}
.footer__item--contact span {
  word-break: break-word;
}
.footer__item--contact:hover {
  color: #fff;
}
.footer__item--content-social {
  display: flex;
  align-items: center;
  width: 12rem;
  justify-content: space-between;
}
.footer__item--content-social div {
  display: flex;
  align-items: center;
}
.footer__item--content-social div span {
  color: #fff;
  font-size: 1.2rem;
}
@media all and (max-width: 992px) {
  .footer__item {
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 480px) {
  .footer {
    padding: 2rem;
  }
}
.footer__section {
  margin: 0 4rem;
}
@media (max-width: 425px) {
  .footer__section {
    margin: 0;
  }
}

.home {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}
.home .hero-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.home-hero-image img {
  height: 30rem;
  width: 100%;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.home .about-wrapper {
  margin: 5rem 0;
  padding: 5rem 0;
  background-color: #edf0ff;
}
.home .about-wrapper .about-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}
.home .about-wrapper .about-content-wrapper h2 {
  margin-bottom: 2rem;
}
.home .about-wrapper .about-content-wrapper p {
  margin-right: 2rem;
}
.home .iso-certification {
  background-color: #191a1a;
  color: white;
  text-align: center;
  padding: 6rem 1rem;
  border-radius: 1rem;
}
@media all and (max-width: 480px) {
  .home .iso-certification {
    text-align: left;
  }
}
.home .iso-certification h2 {
  color: white;
  margin-bottom: 1rem;
}
.home .iso-certification p {
  color: white;
  max-width: 57ch;
  margin: auto;
}
.home .iso-certification .certification-icons {
  margin-top: 4rem;
  display: flex;
  gap: 3rem;
  justify-content: center;
  flex-wrap: wrap;
}
.home .why-us {
  background-color: #edf0ff;
  padding: 5rem 0;
  text-align: justify;
  margin: 5rem 0;
}
.home .why-us h2 {
  margin-bottom: 1rem;
}
.home .why-us-card {
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem;
}
.home .why-us-card img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 20rem;
  width: 100%;
}
.home .why-us-card .whyus-content {
  overflow: auto;
  height: 18rem;
  padding: 0 2rem;
  margin: 2rem 0;
}
.home .why-us-card .whyus-content h3 {
  text-align: initial;
}
.home .why-us-card .whyus-content::-webkit-scrollbar {
  width: 3px;
  /* Handle */
  /* Handle on hover */
}
.home .why-us-card .whyus-content::-webkit-scrollbar:active {
  display: contents;
}
.home .why-us-card .whyus-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.home .why-us-card .whyus-content::-webkit-scrollbar-thumb {
  background: #888;
}
.home .why-us-card .whyus-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.home .home_facilities {
  text-align: justify;
  margin-bottom: 5rem;
}
@media all and (max-width: 992px) {
  .home .home_facilities-content-wrapper {
    margin-left: 0px;
    margin-top: 2rem;
  }
}
.home .home_facilities-content-wrapper h2 {
  margin-bottom: 1rem;
}
.home .home_facilities-content-wrapper p {
  max-width: 40ch;
}
.home .home_facilities-content-wrapper button {
  margin-top: 1rem;
}
.home .Home_testimonials {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.home .Home_testimonials--cards {
  margin-top: 2rem;
}
.home .Home_testimonials--cards .testimonial__cards {
  margin: 10px;
}
.home .Home_testimonials--cards .testimonial-card {
  margin: 0.5rem;
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #d6d5d5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px;
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .home .Home_testimonials--cards .testimonial-card {
    height: 450px;
  }
}
@media (max-width: 768px) and (min-width: 375px) {
  .home .Home_testimonials--cards .testimonial-card {
    height: 350px;
  }
}
@media (max-width: 376px) {
  .home .Home_testimonials--cards .testimonial-card {
    height: 450px;
  }
}
.home .Home_testimonials--cards .testimonial-card p {
  margin-top: 1rem;
}
.home .Home_testimonials--cards .testimonial-card img {
  width: 4rem;
  height: 4rem;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
  border-radius: 3rem;
}
.home .Home_testimonials--cards .testimonial-card .testimonials-detail h4 {
  margin-bottom: 0rem;
}
.home .Home_testimonials--cards .testimonial-card .testimonials-detail p {
  margin-top: 0px;
}
.home .Home_testimonials--cards .slider-controllers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}
.home .Home_testimonials--cards .slider-controllers .sliders-dots {
  display: flex;
  gap: 4px;
}
.home .Home_testimonials--cards .slider-controllers .slider {
  display: flex;
}
.home .Home_testimonials--cards .slider-controllers button {
  background: none;
  border: none;
}

.gallery {
  margin: 2rem 0;
}
.gallery_loader {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery__header h2 {
  margin: 0;
}
.gallery__header--action {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
}
.gallery__header--button {
  padding: 0.4rem 1rem;
  background: transparent;
  border-radius: 71px;
  color: #3c4043;
  border: 2px solid #3c4043;
  font-style: normal;
  font-weight: 500;
  transition: all 150ms;
  font-size: inherit;
  cursor: pointer;
}
.gallery__header--button:hover, .gallery__header--button:active, .gallery__header--button.active {
  border: 2px solid transparent;
  background-color: #efefef;
}
.gallery__grid {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.gallery__grid figure {
  background: rgb(184, 183, 183);
}
.gallery__grid figure img {
  width: 100%;
  height: 15rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.banner {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  margin: 2rem 0;
  height: 36rem;
}
.banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(2, 2, 2, 0.0708332) 62.81%, #222222 100%);
}
.banner img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.banner--info {
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 10%;
  text-align: center;
  color: #fff;
  font-size: 1.1rem;
}
.banner--info h1 {
  color: #fff;
  margin-bottom: 0;
}
@media all and (max-width: 768px) {
  .banner--info h1 {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}
@media all and (max-width: 768px) {
  .banner {
    height: 25rem;
  }
}

.about__message {
  padding: 2rem 0;
}
.about__message .row {
  flex-wrap: wrap-reverse;
}
.about__message--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-overflow: ellipsis;
  height: 100%;
  font-size: 1.1rem;
}
.about__message--content h2 {
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-size: 2rem !important;
}
.about__message--content-overflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 12;
  overflow: hidden;
  margin: 0;
}
.about__message--content-button {
  border: none;
  background-color: transparent;
  color: blue;
  padding: 0;
}
.about__message--content__para {
  text-align: justify;
  margin: 0;
  line-height: 180%;
}
.about__message--img {
  width: 100%;
  height: 100%;
  display: flex;
}
@media all and (max-width: 992px) {
  .about__message--img {
    justify-content: start;
  }
}
.about__message--img figure {
  width: 100%;
}
.about__message--img figure img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.about__vision {
  background-color: #edf0ff;
  padding: 4rem 0;
  margin: 2rem 0;
  text-align: justify;
}
.about__vision--item {
  padding: 2rem 0;
  padding-right: 5rem;
}
.about__vision--item h2 {
  margin: 1rem 0;
}
.about__vision--item p {
  line-height: 180%;
}

.about {
  margin: 2rem 0;
}
.about__management h2 {
  padding: 2rem 0;
}
.about__management--item {
  margin-bottom: 2rem;
  font-size: 1.5rem !important;
}
.about__management--item figure img {
  width: 80%;
  height: auto;
  border-radius: 8px;
}
.about__management--item_name {
  font-size: 1.1rem;
}
.about__management--item_post {
  font-size: 1rem;
  font-weight: 300;
}

.contact {
  margin: 2rem 0;
}
.contact-form {
  margin-right: 2rem;
  margin-bottom: 3rem;
}
@media all and (max-width: 992px) {
  .contact-form {
    margin-right: 0;
  }
}
.contact-form h2 {
  font-size: 2rem;
  line-height: 2rem;
  margin: 0;
}
.contact-form small {
  color: #3c4043;
  font-size: 0.8rem;
}
.contact-form__main {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.contact-form__main label {
  font-size: 0.9rem;
  display: flex;
  margin-bottom: 0.3rem;
}
.contact-form__main input {
  border-radius: 20px;
  border: none;
  background-color: #efefef;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}
.contact-form__main input::-moz-placeholder {
  font-size: 0.9rem;
}
.contact-form__main input::placeholder {
  font-size: 0.9rem;
}
@media all and (max-width: 992px) {
  .contact-form__main input {
    width: 15rem;
  }
}
.contact-form__main--row {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 480px) {
  .contact-form__main--row {
    flex-direction: column;
  }
}
.contact-form__item {
  margin-bottom: 1rem;
}
.contact-form__item textarea {
  border-radius: 20px;
  border: none;
  background-color: #efefef;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}
.contact-form__item textarea::-moz-placeholder {
  font-size: 0.9rem;
}
.contact-form__item textarea::placeholder {
  font-size: 0.9rem;
}
.contact-form__item--message {
  width: 100%;
}
.contact-form__item span {
  display: flex;
  font-size: 0.75rem;
  color: red;
}
.contact-map {
  height: 100%;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 6px;
}
@media all and (max-width: 992px) {
  .contact-map {
    height: 25rem;
  }
}
.contact .submit {
  width: 100%;
  padding: 0.5rem 0;
}

.facilities_one-content-wrapper {
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.facilities_collection {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  text-align: justify;
}

.scrolltop {
  background: #2956c9;
  color: white;
  padding: 0.7rem 1rem;
  cursor: pointer;
  display: inline;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  display: inline-block;
  transition: visibility 0.5s ease-in-out;
  box-shadow: 1px 2px 5px rgba(139, 139, 139, 0.637);
  z-index: 99;
  border-radius: 50%;
}

.notVisible {
  position: fixed;
  bottom: 5rem;
  right: 3rem;
  display: none;
  transition: visibility 0.5s ease-in-out;
  z-index: 99;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
}
.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}
.popup-container-item {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  background-color: white;
  border: 1px solid rgba(57, 57, 57, 0.3);
  border-radius: 10px;
}
@media all and (max-width: 576px) {
  .popup-container-item {
    gap: 2rem;
    padding: 0.5rem;
  }
}
.popup-container-item-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.popup-container-item-header h3 {
  margin: 0;
  padding: 0;
}
.popup-container-item-header button {
  background: transparent;
  outline: none;
  border: none;
  font-size: 20px;
}
.popup-container-item-image {
  text-align: center;
  min-height: 70vh;
}
.popup-container-item-image .carousel-control-next,
.popup-container-item-image .carousel-control-prev {
  display: block;
  opacity: 1;
  padding-top: 20px;
}
@media all and (max-width: 576px) {
  .popup-container-item-image .carousel-control-next,
  .popup-container-item-image .carousel-control-prev {
    padding-top: 0;
    opacity: 0.4;
  }
}
@media all and (max-width: 576px) {
  .popup-container-item-image {
    min-height: 40vh;
  }
}
.popup-container-item-image span {
  font-size: 20px;
}
.popup-container-item-image img {
  width: 768px;
}
@media all and (max-width: 576px) {
  .popup-container-item-image img {
    width: 80vw;
  }
}/*# sourceMappingURL=App.css.map */