.scrolltop {
  background: color(primary, base);
  color: white;
  padding: 0.7rem 1rem;
  cursor: pointer;
  display: inline;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  display: inline-block;
  transition: visibility 0.5s ease-in-out;
  box-shadow: 1px 2px 5px rgba(139, 139, 139, 0.637);
  z-index: 99;
  border-radius: 50%;

  //   &-button {
  //     color: black;
  //   }
}

.notVisible {
  position: fixed;
  bottom: 5rem;
  right: 3rem;
  display: none;
  transition: visibility 0.5s ease-in-out;
  z-index: 99;
}
