.home {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  .hero {
    &-contents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  &-hero-image {
    img {
      height: 30rem;
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  .about-wrapper {
    // width: 100vw;
    margin: 5rem 0;
    padding: 5rem 0;
    background-color: $secondary-bg-color;

    .about-content-wrapper {
      h2 {
        margin-bottom: 2rem;
      }
      p {
        margin-right: 2rem;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: justify;
    }
  }

  .iso-certification {
    background-color: #191a1a;
    color: white;
    text-align: center;
    padding: 6rem 1rem;
    border-radius: 1rem;
    @include responsive-for(xs) {
      text-align: left;
    }
    h2 {
      color: white;
      margin-bottom: 1rem;
    }
    p {
      color: white;
      max-width: 57ch;
      margin: auto;
    }
    .certification-icons {
      margin-top: 4rem;
      display: flex;
      gap: 3rem;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .why-us {
    background-color: $secondary-bg-color;
    padding: 5rem 0;
    text-align: justify;
    margin: 5rem 0;
    h2 {
      margin-bottom: 1rem;
    }
    &-card {
      background-color: white;
      border-radius: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 1rem;
      img {
        // padding: 0.75rem;
        object-fit: cover;
        height: 20rem;
        width: 100%;
      }
      .whyus-content {
        overflow: auto;
        height: 18rem;
        padding: 0 2rem;
        margin: 2rem 0;
        h3 {
          text-align: initial;
        }
        &::-webkit-scrollbar {
          width: 3px;
          &:active {
            display: contents;
          }
          &-track {
            background: #f1f1f1;
          }

          /* Handle */
          &-thumb {
            background: #888;
          }

          /* Handle on hover */
          &-thumb:hover {
            background: #555;
          }
        }
      }
    }
  }

  .home_facilities {
    text-align: justify;
    margin-bottom: 5rem;
    &-content-wrapper {
      // margin-top: 2rem;
      @include responsive-for(lg) {
        margin-left: 0px;
        margin-top: 2rem;
      }
      h2 {
        margin-bottom: 1rem;
      }
      p {
        max-width: 40ch;
      }
      button {
        margin-top: 1rem;
      }
    }
  }

  .Home_testimonials {
    margin-top: 5rem;
    margin-bottom: 5rem;

    &--cards {
      margin-top: 2rem;
      .testimonial__cards {
        margin: 10px;
      }
      .testimonial-card {
        margin: 0.5rem;
        padding: 2rem 1rem;
        border-radius: 0.5rem;
        border: 1px solid #d6d5d5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 380px;

        @media (min-width: 1024px) and (max-width: 1200px) {
          height: 450px;
        }
        @media (max-width: 768px) and (min-width: 375px) {
          height: 350px;
        }
        @media (max-width: 376px) {
          height: 450px;
        }
        p {
          margin-top: 1rem;
        }
        img {
          width: 4rem;
          height: 4rem;
          object-fit: cover;
          overflow: hidden;
          border-radius: 3rem;
        }

        .testimonials-detail {
          h4 {
            margin-bottom: 0rem;
          }
          p {
            margin-top: 0px;
          }
        }
      }

      .slider-controllers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        .sliders-dots {
          display: flex;
          gap: 4px;
        }
        .slider {
          display: flex;
        }
        button {
          background: none;
          border: none;
        }
      }
    }
  }
}
