.banner {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  margin: 2rem 0;
  height: 36rem;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(2, 2, 2, 0.0708332) 62.81%,
      #222222 100%
    );
  }
  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
  &--info {
    position: absolute;
    left: 5%;
    right: 5%;
    bottom: 10%;
    text-align: center;
    color: #fff;
    font-size: 1.1rem;

    h1 {
      color: #fff;
      margin-bottom: 0;
      @include responsive-for(md) {
        font-size: 2.8rem;
        line-height: 2.8rem;
      }
    }
  }
  @include responsive-for(md) {
    height: 25rem;
  }
}
