.gallery {
  margin: 2rem 0;

  &_loader {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__header {
    h2 {
      margin: 0;
    }
    &--action {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 1.5rem;
    }
    &--button {
      // margin-top: 1.5rem;
      @include galleryButton;
    }
  }
  &__grid {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    figure {
      background: rgb(184, 183, 183);
      img {
        width: 100%;
        height: 15rem;
        object-fit: cover;
      }
    }
  }
}
