.facilities_one-content-wrapper {
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.facilities_collection {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  text-align: justify;
}
