.about {
  &__vision {
    background-color: $secondary-bg-color;
    padding: 4rem 0;
    margin: 2rem 0;
    text-align: justify;
    &--item {
      padding: 2rem 0;
      padding-right: 5rem;
      h2 {
        margin: 1rem 0;
      }

      p {
        line-height: 180%;
      }
    }
  }
}
